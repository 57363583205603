

/**
 * Theme for being used in the business-facing app.
 */

import { colors, palette } from 'ui/themes/colors'

// DO NOT USE THESE COLORS! THEY ARE DEPRECATED AND WILL BE REMOVED IN THE FUTURE
const deprecatedColors = {
  primary: colors['Primary/Base'],
  secondary: colors['Text/Secondary'],
  success: colors['Success/Base'],
  info: colors['Accent1/Base'],
  warning: colors['Warning/Base'],
  danger: colors['Danger/Base'],

  light: palette.grey[20],
  dark: colors['Text/Primary'],

  borderHover: palette.grey[40]
}

export const color = {
  black: palette.black,
  white: palette.white,
  transparent: 'transparent',

  // Ease of use colors, corresponding to the Base color of the palette.
  // e.g. blue =~ colors['Primary/Base']
  blue: palette.blue[60],
  green: palette.green[50],
  orange: palette.orange[50],
  red: palette.red[60],
  purple: palette.purple[60],
  grey: palette.grey[30],

  ...colors,
  ...deprecatedColors
}

const orderStatusColors = (status) => {
  const accent = {
    new: 'Accent2',
    overdue: 'Accent6',
    concept: 'Accent2',
    reserved: 'Accent1',
    started: 'Accent5',
    stopped: 'Accent4',
    archived: 'Accent2',
    canceled: 'Accent2',
    mixed: 'Accent2'
  }[status]

  if (status === 'mixed') {
    return {
      text: colors[`${accent}/Foreground3`],
      color: colors[`${accent}/Foreground3`],
      background: colors[`${accent}/Controlvariant2`],
      border: colors[`${accent}/Outline`]
    }
  } else {
    return {
      text: colors[`${accent}/Foreground3`],
      color: colors[`${accent}/Foreground1`],
      background: colors[`${accent}/Controlvariant1`],
      border: colors[`${accent}/Controlvariant1`]
    }
  }
}

const productStatusColors = (status) => {
  const accent = {
    already_booked: 'Accent6',
    reserved: 'Accent1',
    started: 'Accent5',
    specified: 'Accent2',
    stopped: 'Accent4',
    concept: 'Accent3',
    in_stock: 'Accent4',
    available: 'Accent2',
    unavailable: 'Accent6',
    shortage: 'Accent6',
    overdue: 'Accent6',
    expected: 'Accent3',
    expired: 'Accent3',
    archived: 'Accent3'
  }[status]

  return {
    color: colors[`${accent}/Foreground3`],
    background: colors[`${accent}/Controlvariant2`],
    border: colors[`${accent}/Outline`]
  }
}

export const paymentStatusColors = (status) => {
  const accent = {
    'confirmed': 'Accent4',
    'unconfirmed': 'Accent2',
    'payment_due': 'Accent5',
    'revised': 'Accent2',
    'partially_paid': 'Accent1',
    'paid': 'Accent4',
    'overpaid': 'Accent6',
    'process_deposit': 'Accent5',
    'success': 'Accent4',
    'failure': 'Accent6',
    'pending': 'Accent1',
    'failed': 'Accent6',
    'canceled': 'Accent5',
    'sca pending': 'Accent5',
    'loading': 'Accent2'
  }[status]

  return {
    color: colors[`${accent}/Foreground1`],
    background: colors[`${accent}/Controlvariant1`],
    border: colors[`${accent}/Controlvariant1`]
  }
}

const confirmedStatusColors = (confirmed) => {
  const accent = confirmed ? 'Accent4' : 'Accent2'

  return {
    color: colors[`${accent}/Foreground1`],
    background: colors[`${accent}/Controlvariant1`],
    border: colors[`${accent}/Controlvariant1`]
  }
}

/**
 * We use a 4px grid system for spacing. This function calculates the pixel value for a given grid unit.
 * 0.5 grid units = 2px
 * 1 grid unit = 4px
 * 2 grid units = 8px
 * 3 grid units = 12px
 * 4 grid units = 16px
 * 5 grid units = 20px
 * 6 grid units = 24px
 * 7 grid units = 28px
 * 8 grid units = 32px
 * 9 grid units = 36px
 * 10 grid units = 40px
 * 11 grid units = 44px
 * 12 grid units = 48px
 * 16 grid units = 64px
 */
const grid = (unit) => {
  if (!Number.isInteger(unit) && unit !== 0.5) {
    throw new Error('Grid units must be integers or 0.5')
  }
  return `${unit * 4}px`
}

const units = {
  0.5: grid(0.5),
  1: grid(1),
  2: grid(2),
  3: grid(3),
  4: grid(4),
  5: grid(5),
  6: grid(6),
  7: grid(7),
  8: grid(8),
  9: grid(9),
  10: grid(10),
  11: grid(11),
  12: grid(12),
  13: grid(13),
  14: grid(14),
  15: grid(15),
  16: grid(16)
}

const font = {
  base: 'proxima-nova',
  fallback: 'sans-serif',
  mono: 'Monospace',
  handwriting: 'Caveat',
  fontAwesomeStyle: 'far',
  size: {
    body: '20px',
    xs: '12px',
    sm: '12px',
    md: '14px',
    mdplus: '16px',
    lg: '18px',
    lgplus: '22px',
    xl: '26px',
    xxl: '34px',
    xxxl: '48px',
    largeIcon: '66px'
  },
  weight: {
    light: '300',
    lighter: '400',
    normal: '500',
    semibold: '600',
    bold: '700'
  }
}

font.body = `${font.base}, ${font.fallback}`

const spacingUnits = {
  xxs: grid(0.5),
  xs: grid(1),
  xsplus: grid(2),
  sm: grid(3),
  md: grid(4),
  mdplus: grid(5),
  lg: grid(6),
  lgplus: grid(7),
  xl: grid(8),
  xlplus: grid(11),
  xxl: grid(12),
  xxxl: grid(16)
}

const size = {
  borderRadius: {
    xxs: '2px',
    xs: '6px',
    sm: '6px',
    md: '8px',
    mdplus: '12px',
    lg: '18px',
    xl: '26px',
    xxl: '34px'
  },
  borderWidth: {
    xs: '1px',
    sm: '1px',
    md: '1px',
    lg: '1px',
    xl: '1px',
    xxl: '1px'
  },
  controlHeight: {
    xs: '26px',
    sm: '38px',
    md: '42px',
    lg: '48px',
    xl: '66px',
    xxl: '66px'
  },
  margin: {
    ...spacingUnits,
    auto: 'auto'
  },
  padding: spacingUnits,
  shadow: {
    sm: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    md: 'rgba(0, 0, 0, 0.1) 0 6px 14px 0',
    lg: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    lgplus: 'rgba(0, 0, 0, 0.12) 0px 4px 10px 0px',
    xl: 'rgba(0, 0, 0, 0.12) 0px 8px 20px 0px',
    xxl: 'rgba(0, 0, 0, 0.2) 0px 4px 40px 0px'
  },
  headerHeight: {
    xs: '62px',
    sm: '62px',
    md: '62px',
    lg: '62px'
  },
  navigationWidth: {
    xs: '41px',
    sm: '41px',
    md: '41px',
    lg: '41px'
  },
  navigationWidthExpanded: {
    xs: '201px',
    sm: '201px',
    md: '201px',
    lg: '201px'
  },
  sidebarWidth: {
    xs: '220px',
    sm: '220px',
    md: '220px',
    lg: '220px'
  },
  screenSizes: {
    xs: '0px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1800px'
  }
}

const opacity = {
  disabled: 0.6,
  destroying: 0.4
}

const zIndex = [
  1,
  100,
  200,
  300,
  400
]

export default () => {
  return {
    color,
    font,
    size,
    opacity,
    zIndex,
    orderStatusColors,
    productStatusColors,
    paymentStatusColors,
    confirmedStatusColors,
    units
  }
}
